.header-component {
  background-color: #F0F0F0;
  padding: 10px 0px;
  border-bottom: 1px solid #CCC; }
  .header-component .col-12 {
    display: flex; }
  .header-component .headerItems {
    height: 40px;
    align-items: center;
    white-space: nowrap; }
  .header-component .headerItemsLeft {
    flex: 1 1 auto;
    position: relative;
    left: -15px; }
  .header-component .headerItemsRight {
    position: relative;
    right: -15px; }
  .header-component .headerLogo.bp3-button.bp3-minimal {
    height: 40px;
    color: #4286f4;
    font-size: 30px;
    font-weight: 800;
    letter-spacing: 1px;
    line-height: 30px; }
  .header-component .bp3-button.bp3-intent-primary {
    margin-left: 1em; }
  .header-component .avatar-button.bp3-button.bp3-large {
    padding: 0px 5px; }
  .header-component.simple {
    background-color: white;
    border-bottom: 0px solid #CCC; }
    .header-component.simple .headerLogo {
      color: #4286f4; }
    .header-component.simple .bp3-button.bp3-minimal {
      color: #333; }
      .header-component.simple .bp3-button.bp3-minimal:hover {
        color: #222; }
