.avatar-component {
  background-color: #767676;
  color: white;
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid white;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  font-weight: bold;
  vertical-align: middle;
  user-select: none; }
