.footer ul {
  text-align: right;
  list-style: none;
  margin: 0px;
  border-top: 1px solid #CCC;
  padding: 1em 0em;
  width: 100%;
  white-space: nowrap; }

.footer li {
  text-decoration: inherit;
  padding: 0em 0em 0em 2em;
  display: inline-block; }

.footer a {
  color: inherit;
  user-select: none; }

@media only screen and (max-width: 720px) {
  /* Used to be 45em */
  .footer ul {
    text-align: center; }
  .footer li {
    padding: 1em 0em;
    display: block; } }
